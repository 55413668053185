/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";

// Sections for this page
// (we've divided this page into multiple react components to make it a bit more readable)
// import Servicios from "./SectionsSections/Servicios.js";
import Footer from "./SectionsSections/Footer.js";
import Infraestructura from "./SectionsSections/Infraestructura.js";
import SecardorasRefrigerativas from "./SectionsSections/SecardorasRefrigerativas.js";
import SecardorasRegenerativas from "./SectionsSections/SecardorasRegenerativas.js";
import CompresoresTornillo from "./SectionsSections/CompresoresTornillo.js";
import CompresoresPistonLubricados from "./SectionsSections/CompresoresPistonLubricados.js";
import CompresoresLibresDeAceite from "./SectionsSections/CompresoresLibresDeAceite.js";
import Servicios from "./SectionsSections/Servicios.js";

export default function Index() {
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("index-page");
    return function cleanup() {
      document.body.classList.remove("index-page");
    };
  }, []);
  return (
    <>
      <ColorNavbar />
      <div className="wrapper" ref={wrapper}>
        <IndexHeader />
        <div className="main">
          <div className="cd-section" id="servicios">
          <Servicios /> 
          <SecardorasRefrigerativas />
          <br />
          <br />
          <br />
          <SecardorasRegenerativas />
          <br />
          <br />
          <br />
          <CompresoresTornillo />
          <br />
          <br />
          <br />
          <CompresoresPistonLubricados />
          <br />
          <br />
          <br />
          <CompresoresLibresDeAceite />
          </div>
          <Infraestructura />
        </div>
        <Footer />
      </div>
    </>
  );
}
