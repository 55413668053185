import React from 'react';
// react plugin used to create charts

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";

import Footer from "./SectionsSections/Footer";

import hertz1 from "../assets/pdf/HOJA_DE_FORMATOhertz.pdf"
import hertz2 from "../assets/pdf/HERTZ-Catalogo-General-2018.pdf"
import airmac from "../assets/pdf/AIRMAC-2017.pdf"
import hertz_hs_series from "../assets/pdf/CATALOGO_COMPRESOR_SCROLL.pdf"

import dh_filtros_ng from "../assets/pdf/FILTROS_PARKER_NUEVOS.pdf"
import dh_filtros_alta_presion from "../assets/pdf/FILTROS_ALTA_PRESION_DOMNICK _H_5000_LBS.pdf"


import PdfVisor from './SectionsSections/PdfVisor';


export default function Productos() {
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  }, []);
    
    
  return (
    <>
      <ColorNavbar />
      <div className="wrapper" ref={wrapper}>
        
        <section className="section section-lg">
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <h1 className="text-center">Catalogos</h1>
                
                <PdfVisor file={hertz1} title="HERTZ - Serie HBD"/>
                <PdfVisor file={hertz2} title="HERTZ - Catalogo General"/>
                <PdfVisor file={airmac} title="AIRMAC"/>
                <PdfVisor file={hertz_hs_series} title="HERTZ - Serie HS"/>
                <PdfVisor file={dh_filtros_ng} title="DOMINIK HUNTER - Filtros Nueva Gama"/>
                <PdfVisor file={dh_filtros_alta_presion} title="DOMINIK HUNTER - Filtros Alta Presión"/>              
              </Col>
            </Row>
            
          </Container>
        </section>
        
        <Footer />
      </div>
    </>
  );
}
