import React from 'react';

import {
  Button,
  
  CardTitle,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  
} from "reactstrap";

// core components
const items1 = [
  {
    content: (
      <Container>
        <Row>
          <Col className="mr-auto" md="5">
            <div className="space-40" />
            <CardTitle tag="h3">Compresores de Aire</CardTitle>
            <h3 className="text-warning">• • •</h3>
            <h4 className="description">
              <strong>TORNILLO ROTATIVO:</strong> Lubricados y velocidad variable. Capacidades de 5 Hp a 100 Hp. <br /><br />
              <strong>RECIPROCANTES (pistones):</strong> Alta y baja presión, lubricados, libres de aceite y paquetes grado médico.<br /><br />
              <strong>SCROLL:</strong> Grado farmacéutico y alimenticio. Capacidades de 5 Hp a 15 Hp.<br /><br />
              <strong>SCROLL:</strong> Paquetes grado médico montado sobre base. Capacidades de 5 Hp a 40 Hp.<br /><br />
            </h4>
            <Button
              color="warning"
              href="/productos"
              
            >
              Productos
            </Button>
          </Col>
          <Col className="ml-auto" md="6">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/fotos/servicio_compresores_piston/1.jpg").default}
            />
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "0",
  },
  {
    content: (
      <Container>
        <Row>
          <Col className="mr-auto" md="5">
            <div className="space-100" />
            <CardTitle tag="h3">Secadoras de para aire comprimido</CardTitle>
            <h3 className="text-warning">• • •</h3>
            <h4 className="description">
              <strong>REFRIGERATIVAS:</strong> No cíclicas con puntos de roció de 3 °C. <br /><br />
              <strong>REGENERATIVAS:</strong> Con puntos de rocío hasta de –20 A -70 ºC.
            </h4>
            <Button
              color="warning"
              href="/productos"
              
            >
              Productos
            </Button>
          </Col>
          <Col className="ml-auto" md="6">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/fotos/secadoras_refrigerativas/1.png").default}
            />
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "1",
  },
  {
    content: (
      <Container>
        <Row>
          <Col className="mr-auto" md="5">
            <div className="space-100" />
            <CardTitle tag="h3">Filtros para tratamiento de aire comprimido </CardTitle>
            <h3 className="text-warning">• • •</h3>
            <h4 className="description">
              <strong>FILTROS:</strong> De partículas, coalescentes de 1 y 0.01 micras, carbón activado, separadores de humedad y separadores de agua-Aceite.
            </h4>
            <Button
              color="warning"
              href="/productos"
              
            >
              Productos
            </Button>
          </Col>
          <Col className="ml-auto" md="6">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/filtros.jpg").default}
            />
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "1",
  },
   {
    content: (
      <Container>
        <Row>
          <Col className="mr-auto" md="5">
            <div className="space-100" />
            <CardTitle tag="h3">Refacciones </CardTitle>
            <h3 className="text-warning">• • •</h3>
            <h4 className="description">
              Ofrecemos refacciones originales para compresores de tornillo, de pistones y secadores de aire en marcas Hertz, Atlas Copco, Ingersoll-Rand, Parker, aceite sintético, elementos filtrantes de línea marca Hertz.
            </h4>
            <Button
              color="warning"
              href="/productos"
              
            >
              Productos
            </Button>
          </Col>
          <Col className="ml-auto" md="6">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/hertz.png").default}
            />
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "1",
  },
   {
    content: (
      <Container>
        <Row>
          <Col className="mr-auto" md="5">
            <div className="space-100" />
            <CardTitle tag="h3">Servicio técnico especializado </CardTitle>
            <h3 className="text-warning">• • •</h3>
            <h4 className="description">
              Contamos con técnicos calificados para proporcionar servicio de mantenimiento preventivo y correctivo a compresores y secadores. <br /><br />
              Contamos con pólizas y convenios de mantenimiento para sus equipos.
            </h4>
            <Button
              color="warning"
              href="/contacto"
              
            >
              Contactanos
            </Button>
          </Col>
          <Col className="ml-auto" md="6">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/fotos/servicios/serviciotecnicoespecializado.jpg").default}
            />
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "1",
  },
   {
    content: (
      <Container>
        <Row>
          <Col className="mr-auto" md="5">
            <div className="space-100" />
            <CardTitle tag="h3">Accesorios </CardTitle>
            <h3 className="text-warning">• • •</h3>
            <h4 className="description">
              Recipientes para aire comprimido, trampas de condensados, válvulas de dren electrónicas.
            </h4>
            <Button
              color="warning"
              href="/productos"
              
            >
              Productos
            </Button>
          </Col>
          <Col className="ml-auto" md="6">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/fotos/servicio_compresores_piston/6.jpg").default}
            />
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "1",
  },
   {
    content: (
      <Container>
        <Row>
          <Col className="mr-auto" md="5">
            <div className="space-100" />
            <CardTitle tag="h3">Instalación y Asesoria </CardTitle>
            <h3 className="text-warning">• • •</h3>
            <h4 className="description">
              Ofrecemos Asesoría técnica y/o la instalación y montaje de sus equipos, así como instalación de tubería en aluminio anodizado y acero al carbón, tanques y equipos a través de personal capacitado. 
            </h4>
            <Button
              color="warning"
              href="/contacto"
              
            >
              Contactanos
            </Button>
          </Col>
          <Col className="ml-auto" md="6">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/fotos/compresores_libres_aceite/3.jpg").default}
            />
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "1",
  },
];

export default function Servicios() {

     const [carousel1Index, setCarousel1Index] = React.useState(0);
  const [carousel2Index, setCarousel2Index] = React.useState(0);
  const [animating1, setAnimating1] = React.useState(false);
  const [animating2, setAnimating2] = React.useState(false);
  const onExiting = (carousel) => {
    if (carousel === 1) {
      setAnimating1(true);
    } else {
      setAnimating2(true);
    }
  };

  const onExited = (carousel) => {
    if (carousel === 1) {
      setAnimating1(false);
    } else {
      setAnimating2(false);
    }
  };
  const next = (carousel, items) => {
    if (carousel === 1) {
      if (animating1) {
        return;
      }
    } else {
      if (animating2) {
        return;
      }
    }
    let currentIndex = -1;
    if (carousel === 1) {
      currentIndex = carousel1Index;
    } else {
      currentIndex = carousel2Index;
    }
    const nextIndex = currentIndex === items.length - 1 ? 0 : currentIndex + 1;
    if (carousel === 1) {
      setCarousel1Index(nextIndex);
    } else {
      setCarousel2Index(nextIndex);
    }
  };
  const previous = (carousel, items) => {
    if (carousel === 1) {
      if (animating1) {
        return;
      }
    } else {
      if (animating2) {
        return;
      }
    }
    let currentIndex = -1;
    if (carousel === 1) {
      currentIndex = carousel1Index;
    } else {
      currentIndex = carousel2Index;
    }
    const nextIndex = currentIndex === 0 ? items.length - 1 : currentIndex - 1;
    if (carousel === 1) {
      setCarousel1Index(nextIndex);
    } else {
      setCarousel2Index(nextIndex);
    }
  };

    return (
        <>
      <div className="cd-section" id="servicios">
        {/* ********* TESTIMONIALS 1 ********* */}
        <div className="testimonials-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">Los Productos que Ofrecemos son:</h2>
              </Col>
            </Row>
            <Carousel
              activeIndex={carousel1Index}
              next={() => next(1, items1)}
              previous={() => previous(1, items1)}
              className="carousel-team"
            >
              {items1.map((item, key) => {
                return (
                  <CarouselItem
                    onExiting={() => onExiting(1)}
                    onExited={() => onExited(1)}
                    key={key}
                  >
                    {item.content}
                  </CarouselItem>
                );
              })}
              <a
                className="carousel-control-prev"
                data-slide="prev"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  previous(1, items1);
                }}
                role="button"
              >
                <i className="tim-icons icon-minimal-left" />
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                data-slide="next"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  next(1, items1);
                }}
                role="button"
              >
                <i className="tim-icons icon-minimal-right" />
                <span className="sr-only">Next</span>
              </a>
            </Carousel>
          </Container>
        </div>
        {/* ********* END TESTIMONIALS 1 ********* */}
      </div>
    </>
    )
}
