/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import "./IndexHeader.css";

export default function Index() {
  return (
    <>
      
      {/* ********* HEADER 4 w/ VIDEO ********* */}
        <div className="header header-4">
          <div className="header-wrapper">
            <div className="page-header header-video header-filter">
              <div className="overlay" />
              <video
                autoPlay="autoplay"
                loop="loop"
                muted="muted"
                playsInline="playsinline"
              >
                <source
                  src={require("assets/video/clouds.mp4").default}
                  type="video/mp4"
                />
              </video>
              <div className="squares square1" />
              <div className="squares square2" />
              <div className="squares square3" />
              <div className="squares square4" />
              <div className="squares square5" />
              <div className="squares square6" />
              <div className="squares square7" />
              <Container className="text-center">
                <div className="video-text">
                  <br />
                  <br />
                  <br />
                  <img
                        alt="Aire Comprimido de México S.A. de C.V."
                        height="150"
                        src={require("assets/img/logo.png").default}
                        width="300"
                  />
                  <br />
                  <br />
                  <br />
                  <h5 className="description">
                   Aire Comprimido de México S.A. de C.V. es una empresa con 22 años de experiencia proporcionando servicios de calidad para cubrir diversas necesidades de suministro integral; ofreciendo compresores, secadores, filtros, refacciones originales, accesorios, asesoría, renta de equipos e instalaciones.<br /><br />
                   Contamos con técnicos especializados que proporcionan servicios de calidad en mantenimiento preventivo y correctivo, asesoría técnica, instalación y montaje de equipos, red de tubería, estructuras y tanques.<br /><br />
                   Ponemos a su disposición nuestros servicios de mantenimiento a compresores, secadores, elementos filtrantes y refacciones para diversas marcas.


                  </h5>
                  <br />
                </div>
              </Container>
            </div>
          </div>
        </div>
        {/* ********* END HEADER 4 ********* */}
    </>
  );
}
