/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  
  UncontrolledCollapse,

  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  NavLink,
} from "reactstrap";

export default function ColorNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  React.useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);
  const changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {
      setNavbarColor("bg-info");
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      setNavbarColor("navbar-transparent");
    }
  };
  return (
    <>
      <Navbar className={"fixed-top " + navbarColor} expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/index" tag={Link} id="tooltip6619950104">
              <span>AICOM•</span> Aire Comprimido de México
            </NavbarBrand>
            <UncontrolledTooltip delay={0} target="tooltip6619950104">
              Diseñado por Jiyu.mx
            </UncontrolledTooltip>
            <button className="navbar-toggler" id="navigation">
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <UncontrolledCollapse navbar toggler="#navigation">
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <a href="/index" >
                    AICOM• <span></span>
                  </a>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button className="navbar-toggler" id="navigation">
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="mx-auto" navbar>
                  <NavItem className="active">
                    <NavLink to="/index" tag={Link}>
                      Inicio
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/index#servicios">
                      Servicios
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/productos" tag={Link} >
                      Productos
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/catalogos" tag={Link} >
                      Catalogos
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/contacto" tag={Link}>
                      Contacto
                    </NavLink>
                  </NavItem>
                </Nav>
                <Nav className="nav navbar-right" navbar>
                  
                  <NavItem>
                    <NavLink
                      href="https://www.facebook.com/Aire-Comprimido-de-M%C3%A9xico-SA-de-CV-AICOM-465437430888462"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-square" />
                    </NavLink>
                  </NavItem>
                  
                </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
}
