/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes

// reactstrap components
import {
  Badge,
  Card,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import Footer from "views/SectionsSections/Footer";


export default function Contacto() {
  
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("contact-page");
    return function cleanup() {
      document.body.classList.remove("contact-page");
    };
  }, []);
  return (
    <>
      <ColorNavbar />
      <div className="wrapper" ref={wrapper}>
        <div className="page-header header-filter contactus-3">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/contacto.jpg").default + ")",
            }}
          />
          <Container>
            <Row>
              <Col className="text-center" md="12">
                <h1 className="title">¿Deseas contactarnos?</h1>
                <h3>Con gusto te atenderemos para cualquier cotización, duda o sugerencia</h3>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="main">
          <Container fluid>
            <Row className="infos mb-5">
              <Col lg="4">
                <div className="info info-hover">
                  <div className="icon icon-primary">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={
                        require("assets/img/feature-blob/primary.png").default
                      }
                    />
                    <i className="tim-icons icon-square-pin" />
                  </div>
                  <h4 className="info-title">Dirección</h4>
                  <p className="description">Juan, Enrique Dunant 17 B</p><br />
                  <p className="description">El Partidor, 54879 Cuautitlán, Méx.</p>
                </div>
              </Col>
              <Col lg="4">
                <div className="info info-hover">
                  <div className="icon icon-info">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={require("assets/img/feature-blob/info.png").default}
                    />
                    <i className="tim-icons icon-email-85" />
                  </div>
                  <h4 className="info-title">Email</h4>
                  <a href="mailto:aicom.jrios@gmail.com">
                  <p className="description">aicom.jrios@gmail.com</p>
                  </a>
                </div>
              </Col>
              <Col lg="4">
                <div className="info info-hover">
                  <div className="icon icon-warning">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={
                        require("assets/img/feature-blob/warning.png").default
                      }
                    />
                    <i className="tim-icons icon-mobile" />
                  </div>
                  <h4 className="info-title">Telefonos</h4>
                  <p className="description">(55) 58724455</p>
                  <p className="description">(55) 26201353</p>
                  <p className="description">(55) 46127806</p>
                </div>
              </Col>
            </Row>
            <Row className="mt-5 pt-5">
              <Col className="ml-auto mr-auto text-center mt-5" md="8">
                <Badge color="info">Localizanos</Badge>
                <h1 className="title">
                  Nuestras Oficinas
                </h1>
                <h6>Contamos con instalaciones propias incluyendo área de oficinas, almacén de refacciones, taller, así como zona para maniobras.
                </h6>
              </Col>
            </Row>
            <Row className="mb-5 pt-5">
              <Col className="ml-auto mr-auto" lg="10">
                <Card className="card-profile card-horizontal">
                  <Row>
                    <Col xl="12">
                      <div className="no-mask">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3757.2257685968607!2d-99.18053688490504!3d19.660371138451744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f59b51b829d9%3A0xc8d97dbed9989314!2sAire%20Comprimido%20de%20M%C3%A9xico%20S.A.%20de%20C.V.!5e0!3m2!1ses!2smx!4v1632287092427!5m2!1ses!2smx" width="100%" height="450px"  loading="lazy" title="Mapa"></iframe>
                      </div>
                    </Col>
                    
                  </Row>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
        
        <Footer />
      </div>
    </>
  );
}
