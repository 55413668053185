import React from 'react';
// react plugin used to create charts

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components


const items = [
  {
    content: (
      <img
        alt="..."
        className="d-block"
        src={require("assets/img/fotos/servicio_compresores_piston/1.jpg").default}
      />
    ),
    altText: "",
    caption: "",
    src: "cpl0",
  },
  {
    content: (
      <img
        alt="..."
        className="d-block"
        src={require("assets/img/fotos/servicio_compresores_piston/2.jpg").default}
      />
    ),
    altText: "",
    caption: "",
    src: "cpl1",
  },
  {
    content: (
      <img
        alt="..."
        className="d-block"
        src={require("assets/img/fotos/servicio_compresores_piston/3.jpg").default}
      />
    ),
    altText: "",
    caption: "",
    src: "cpl2",
  },
  {
    content: (
      <img
        alt="..."
        className="d-block"
        src={require("assets/img/fotos/servicio_compresores_piston/4.jpg").default}
      />
    ),
    altText: "",
    caption: "",
    src: "cpl3",
  },
  {
    content: (
      <img
        alt="..."
        className="d-block"
        src={require("assets/img/fotos/servicio_compresores_piston/5.jpg").default}
      />
    ),
    altText: "",
    caption: "",
    src: "cpl4",
  },
  {
    content: (
      <img
        alt="..."
        className="d-block"
        src={require("assets/img/fotos/servicio_compresores_piston/6.jpg").default}
      />
    ),
    altText: "",
    caption: "",
    src: "cpl5",
  },
  {
    content: (
      <img
        alt="..."
        className="d-block"
        src={require("assets/img/fotos/servicio_compresores_piston/7.jpg").default}
      />
    ),
    altText: "",
    caption: "",
    src: "cpl6",
  },
  {
    content: (
      <img
        alt="..."
        className="d-block"
        src={require("assets/img/fotos/servicio_compresores_piston/8.jpg").default}
      />
    ),
    altText: "",
    caption: "",
    src: "cpl7",
  },
  
  
];

export default function CompresoresPiston() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("productos");
    return function cleanup() {
      document.body.classList.remove("productos");
    };
  }, []);
    const onExiting = () => {
    setAnimating(true);
  };

  const onExited = () => {
    setAnimating(false);
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  
    
  return (
    <>
      <div className="wrapper" ref={wrapper}>
        
        <section>
          <Container>
            <Row>
              <Col className="mx-auto" lg="6" md="12">
                <h2 className="title">Compresores de Piston Lubricados</h2>
                <div className="stats stats-right">
                  <div className="stars text-success">
                    <i className="fas fa-oil-can" />
                    <i className="fas fa-oil-can ml-5" />
                    <i className="fas fa-oil-can ml-5" />
                    <i className="fas fa-oil-can ml-5" />
                    <i className="fas fa-oil-can ml-5" />
                    <p className="d-inline ml-1"></p>
                  </div>
                </div>
                <br />
                
                {/* <h5 className="category">Descripción</h5>
                <p className="description">
                  Descripcion
                </p>
                <br />
                
                <br /> */}
                
              </Col>
              <Col lg="6" md="12">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    className="mt-5"
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items.map((item, key) => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={key}
                        className="justify-content-center"
                      >
                        {item.content}
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <Button
                      className="btn-icon btn-round"
                      color="warning"
                      name="button"
                      size="sm"
                      type="button"
                    >
                      <i className="tim-icons icon-minimal-left" />
                    </Button>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <Button
                      className="btn-icon btn-round"
                      color="warning"
                      name="button"
                      size="sm"
                      type="button"
                    >
                      <i className="tim-icons icon-minimal-right" />
                    </Button>
                  </a>
                </Carousel>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
}
