import React from 'react';
// react plugin used to create charts

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";

import Footer from "./SectionsSections/Footer";

const items = [
  {
    content: (
      <img
        alt="..."
        className="d-block"
        src={require("assets/img/hertzc2_.png").default}
      />
    ),
    altText: "",
    caption: "",
    src: "0",
  },
  {
    content: (
      <img
        alt="..."
        className="d-block"
        src={require("assets/img/hertzc_.png").default}
      />
    ),
    altText: "",
    caption: "",
    src: "1",
  },
  {
    content: (
      <img
        alt="..."
        className="d-block"
        src={require("assets/img/fotos/productos/1.png").default}
      />
    ),
    altText: "",
    caption: "",
    src: "4",
  },
  {
    content: (
      <img
        alt="..."
        className="d-block"
        src={require("assets/img/fotos/productos/2.png").default}
      />
    ),
    altText: "",
    caption: "",
    src: "5",
  },
  {
    content: (
      <img
        alt="..."
        className="d-block"
        src={require("assets/img/fotos/productos/3.png").default}
      />
    ),
    altText: "",
    caption: "",
    src: "6",
  },
  {
    content: (
      <img
        alt="..."
        className="d-block"
        src={require("assets/img/fotos/productos/4.png").default}
      />
    ),
    altText: "",
    caption: "",
    src: "7",
  },
  {
    content: (
      <img
        alt="..."
        className="d-block"
        src={require("assets/img/fotos/productos/5.png").default}
      />
    ),
    altText: "",
    caption: "",
    src: "8",
  },
];

export default function Productos() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("productos");
    return function cleanup() {
      document.body.classList.remove("productos");
    };
  }, []);
    const onExiting = () => {
    setAnimating(true);
  };

  const onExited = () => {
    setAnimating(false);
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  
    
  return (
    <>
      <ColorNavbar />
      <div className="wrapper" ref={wrapper}>
        
        <section className="section section-lg">
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <h1 className="text-center">Productos</h1>   
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container>
            <Row className="justify-content-center">
              <Col lg="6" md="12">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    className="mt-5"
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items.map((item, key) => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={key}
                        className="justify-content-center"
                      >
                        {item.content}
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <Button
                      className="btn-icon btn-round"
                      color="warning"
                      name="button"
                      size="sm"
                      type="button"
                    >
                      <i className="tim-icons icon-minimal-left" />
                    </Button>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <Button
                      className="btn-icon btn-round"
                      color="warning"
                      name="button"
                      size="sm"
                      type="button"
                    >
                      <i className="tim-icons icon-minimal-right" />
                    </Button>
                  </a>
                </Carousel>
              </Col>
            </Row>
            <Row>
              <Col className="text-center" md="12">
                <h2 className="title">COMPRESORES DE AIRE</h2>
                <div className="stats stats-right">
                  <div className="stars text-success">
                    <i className="fas fa-fan" />
                    <p className="d-inline ml-1"></p>
                  </div>
                </div>
                <br />
                <h5 className="category">TORNILLO ROTATIVO</h5>
                <p className="description">
                  Lubricados y velocidad variable. Capacidades de 5 Hp a 100 Hp.
                </p>
                <br />
                <h5 className="category">RECIPROCANTES (Pistones)</h5>
                <p className="description">
                  
                </p>
                <br />
                <h5 className="category">SCROLL</h5>
                <p className="description">
                  Grado farmacéutico y alimenticio. Capacidades de 5 Hp a 15 Hp.
                </p>
                <br />
                <h5 className="category">SCROLL</h5>
                <p className="description">
                  Paquetes grado médico montado sobre base. Capacidades de 5 Hp a 40 Hp.
                </p>
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              <Col className="text-center" md="12">
                <h2 className="title">SECADORAS PARA AIRE COMPRIMIDO</h2>
                <div className="stats stats-right">
                  <div className="stars text-success">
                    <i className="fas fa-wind" />
                    <p className="d-inline ml-1"></p>
                  </div>
                </div>
                <br />
                <h5 className="category">REFRIGERATIVAS</h5>
                <p className="description">
                  No cíclicas con puntos de roció de 3 °C.
                </p>
                <br />
                <h5 className="category">REGENERATIVAS</h5>
                <p className="description">
                  Con puntos de rocío hasta de –20 A -70 ºC.
                </p>
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              <Col className="text-center" md="12">
                <h2 className="title">FILTROS PARA TRATAMIENTO DE AIRE COMPRIMIDO</h2>
                <div className="stats stats-right">
                  <div className="stars text-success">
                    <i className="fas fa-filter" />
                    <p className="d-inline ml-1"></p>
                  </div>
                </div>
                <br />
                <h5 className="category">FILTROS</h5>
                <p className="description">
                  De partículas, coalescentes de 1 y 0.01 micras, carbón activado, separadores de humedad y separadores de agua-Aceite.
                </p>
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              <Col className="text-center" md="12">
                <h2 className="title">REFACCIONES</h2>
                <div className="stats stats-right">
                  <div className="stars text-success">
                    <i className="fas fa-cogs" />
                    <p className="d-inline ml-1"></p>
                  </div>
                </div>
                <br />
                <p className="description">
                  Ofrecemos refacciones originales para compresores de tornillo, de pistones y secadores de aire en marcas Hertz, Atlas Copco, Ingersoll-Rand, Parker, Aceite sintético, elementos filtrantes de línea marca Hertz.
                </p>
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              <Col className="text-center" md="12">
                <h2 className="title">SERVICIO TÉCNICO ESPECIALIZADO</h2>
                <div className="stats stats-right">
                  <div className="stars text-success">
                    <i className="fas fa-tools" />
                    <p className="d-inline ml-1"></p>
                  </div>
                </div>
                <br />
                <p className="description">
                  Contamos con técnicos calificados para proporcionar servicio de mantenimiento preventivo y correctivo a compresores y secadores. <br />
                  Contamos con pólizas y convenios de mantenimiento para sus equipos.
                </p>
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              <Col className="text-center" md="12">
                <h2 className="title">ACCESORIOS</h2>
                <div className="stats stats-right">
                  <div className="stars text-success">
                    <i className="fas fa-toolbox" />
                    <p className="d-inline ml-1"></p>
                  </div>
                </div>
                <br />
                <p className="description">
                  Recipientes para aire comprimido, trampas de condensados, válvulas de dren electrónicas.
                </p>
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              <Col className="text-center" md="12">
                <h2 className="title">INSTALACIÓN Y ASESORIA</h2>
                <div className="stats stats-right">
                  <div className="stars text-success">
                    <i className="fas fa-hands-helping" />
                    <p className="d-inline ml-1"></p>
                  </div>
                </div>
                <br />
                <p className="description">
                  Ofrecemos Asesoría técnica y/o la instalación y montaje de sus equipos, así como instalación de tubería en aluminio anodizado y acero al carbón, tanques y equipos a través de personal capacitado. 
                </p>
                <br />
                <br />
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
}
