import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

export default function Footer() {
  return (
    <>
      <footer className="footer">
            <Container>
              <Row>
                <Col md="3">
                  <h3 className="title">Aire Comprimido de México S.A. de C.V.</h3>
                </Col>
                <Col md="3">
                  <Nav>
                    <NavItem>
                      <NavLink to="/index" tag={Link}>
                        Inicio
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/contacto" tag={Link}>
                        Contacto
                      </NavLink>
                    </NavItem>
                    
                  </Nav>
                </Col>
                <Col md="3">
                  <Nav>
                    <NavItem>
                      <NavLink href="/index#servicios">
                        Servicios
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/productos" tag={Link}>
                        Productos
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                <Col md="3">
                  <h3 className="title">Siguenos:</h3>
                  <div className="btn-wrapper profile text-left">
                    <Button
                      className="btn-icon btn-neutral btn-round btn-simple ml-1"
                      color="default"
                      href="https://www.facebook.com/Aire-Comprimido-de-M%C3%A9xico-SA-de-CV-AICOM-465437430888462"
                      id="tooltip260428517"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-square" />
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip260428517">
                      Me gusta
                    </UncontrolledTooltip>
                  </div>
                </Col>
              </Row>
            </Container>
          </footer>
    </>
  );
}
