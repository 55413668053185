import React from 'react'
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap'

export default function Infraestructura() {
    return (
        
        <div className="cd-section" id="infraestructura">
        {/* ********* BLOGS 1 ********* */}
        <div className="section blogs-1" id="blogs-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">Infraestructura</h2>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col lg="6">
                <Card
                  className="card-blog card-background"
                  data-animation={true}
                >
                  <div
                    className="full-background"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/fotos/servicio_compresores_tornillo/13.jpg").default +
                        ")",
                    }}
                  />
                  <CardBody>
                    <div className="content-bottom">
                      <h6 className="card-category">Equipos para renta y respaldo en reparaciones</h6>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <CardTitle tag="h3">
                          No te quedaras sin abastecimiento de aire
                        </CardTitle>
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card
                  className="card-blog card-background"
                  data-animation={true}
                >
                  <div
                    className="full-background"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/transporte.jpg").default +
                        ")",
                    }}
                  />
                  <CardBody>
                    <div className="content-bottom">
                      <h6 className="card-category">Contamos con transportes propios</h6>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <CardTitle tag="h3">Trabajo en campo, refacciones y fuerza de ventas</CardTitle>
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
            <Row>
              <Col lg="12">
                <Card
                  className="card-blog card-background"
                  data-animation={true}
                >
                  <div
                    className="full-background"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/fotos/secadoras_refrigerativas/3.jpg").default +
                        ")",
                    }}
                  />
                  <CardBody>
                    <div className="content-bottom">
                      <h6 className="card-category">Herramental especializado</h6>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <CardTitle tag="h3">Mantenimientos preventivos y correctivos</CardTitle>
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END BLOGS 1 ********* */}
        </div>
    )
}
